import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import ActivateAddon from './ActivateAddon';

function facilityPopup({
  currentName,
  activateAccount,
  enablePaymentAccess,
  activateSubscription,
  recordPayment,
  facilityId,
  facilityInfo,
  lockUnlockAccount,
  practicingLicense,
}) {
  const { name } = useSelector((state) => state.country);
  const history = useHistory();
  const [addon, setAddon] = useState(false);
  // console.log('facility info', facilityInfo);
  return (
    <div>
      <p
        style={{
          color: '#2254D3',
          fontStyle: 'italic',
        }}
      >
        <u>For {currentName}</u>
      </p>
      <p className="" onClick={activateAccount}>
        Activate/Disable account
      </p>
      <p onClick={activateSubscription}>
        Deactivate/Activate Facility Subscription
      </p>
      <p className="" onClick={lockUnlockAccount}>
        Lock/Unlock account
      </p>
      <p className="">Message user</p>
      <p className="">Email user</p>
      <p className="">View billing history</p>
      <p onClick={() => setAddon(!addon)}>Activate Addon</p>
      <p
        onClick={() =>
          history.push({
            pathname: `/thc-admin/${name}/facility-dataset/${facilityId}`,
            state: facilityInfo,
          })
        }
      >
        View pharmacy dataset
      </p>
      <p className="">
        {practicingLicense ? (
          <a href={practicingLicense} target="_blank">
            View practice license
          </a>
        ) : (
          <p className="text-danger">Practice License not uploaded</p>
        )}
      </p>
      <p className="">View subscriber summary</p>
      <p className="" onClick={enablePaymentAccess}>
        Mobile payment access ENABLED/DISABLED
      </p>
      <p className="">Delivery service ENABLED/DISABLED</p>
      <p className="">Select delivery handler SELF/THC/NONE</p>
      <p onClick={recordPayment}>Record payment and activate subscription</p>

      {addon && (
        <ActivateAddon
          createAddon={addon}
          setCreateAddon={setAddon}
          facilityId={facilityId}
          facilityName={currentName}
          facilityType={facilityInfo?.facility_type}
        />
      )}
    </div>
  );
}

export default facilityPopup;

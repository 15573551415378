import React from 'react';
import CheckBox from '@components/formInputs/checkbox';
import Input from '@components/formInputs/FormInputComponent';
import styled from 'styled-components';

function charges({
  labelStyle,
  deductionValue,
  flatFeeValue,
  walletValue,
  isChecked,
  onchangeCheckbox,
  onChangeDeduction,
  onChangeFlatFee,
  onChangeWallet,
  checkboxLabel,
  description,
  onChangeCategory,
  // category,
}) {
  return (
    <StyleWrapper>
      <div className="form-row">
        <CheckBox
          // label={checkboxLabel}
          labelStyle={labelStyle}
          isChecked={isChecked}
          onChangeFunction={onchangeCheckbox}
        />

        <Input
          onChange={onChangeCategory}
          value={checkboxLabel}
          height="26px"
          borderColor="#2254D3"
        />
        <Input
          onChange={onChangeDeduction}
          value={deductionValue}
          height="26px"
          borderColor="#2254D3"
        />
        <Input
          onChange={onChangeFlatFee}
          value={flatFeeValue}
          height="26px"
          borderColor="#2254D3"
        />
        <Input
          onChange={onChangeWallet}
          value={walletValue}
          height="26px"
          borderColor="#2254D3"
        />

        <p>{description}</p>
      </div>
    </StyleWrapper>
  );
}

export default charges;

const StyleWrapper = styled.div`
  .form-row {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.3fr 0.3fr 0.3fr 2fr;
    align-items: center;
  }
  p {
    font-size: 14px;
  }
  .input-wrapper {
    display: flex;
    gap: 5px;
  }
`;

import React, { useState, useEffect } from 'react';
import PayoutOptions from '../payoutOption';
import Charges from '../packageServices';
// import { updateTransaction } from '@redux/actions/hospitalActions/subscription_transaction_actions';
// import { useDispatch } from 'react-redux';
import { StyleWrapper } from '../styleWrapper';
import Button from '@components/Button/Button';
import { useFetchTransaction } from '@hooks/queries';
import request, { headers } from '@request';
import { toast } from 'react-toastify';
const index = () => {
  const { data, refetch } = useFetchTransaction('PHARMACY');
  // const dispatch = useDispatch();
  const diagnosticData = data?.data?.data?.data;
  const [updatedData, setUpdatedData] = useState();

  useEffect(() => {
    if (diagnosticData) {
      setUpdatedData(diagnosticData);
    }
  }, [data]);

  const onchangeCheckbox = (index) => {
    const newData = [...updatedData]; // make a shallow copy of data
    newData[index] = {
      ...newData[index],
      is_active: !newData[index].is_active,
    };
    setUpdatedData(newData); // Update the local state with the new data
  };

  const onChangeDeduction = (index, e) => {
    const newData = [...updatedData];
    newData[index].percentage_deduction = e.target.value;
    setUpdatedData(newData);
  };

  const onChangeFlatFee = (index, e) => {
    const newData = [...updatedData];
    newData[index].fee_cap = e.target.value;
    setUpdatedData(newData);
  };

  const onChangeWallet = (index, e) => {
    const newData = [...updatedData];
    newData[index].payment_mode = e.target.value;
    setUpdatedData(newData);
  };

  const submitUpdate = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem('token');

    try {
      await request.post(
        `utils/transaction-charge-update/`,
        { payload: updatedData },
        headers(token)
      );
      refetch();
      toast.success('payment mode update');
    } catch (error) {
      console.log('error');
    }
  };
  const onChangeCategory = (index, e) => {
    const newData = [...updatedData];
    newData[index].category = e.target.value;
    setUpdatedData(newData);
  };
  return (
    <StyleWrapper>
      <div>
        <PayoutOptions facility={'PHARMACY'} />
      </div>
      <div className="container">
        {updatedData &&
          updatedData.map(
            (
              {
                is_active,
                payment_mode,
                percentage_deduction,
                fee_cap,
                category,
                // description,
                note,
              },
              index
            ) => (
              <Charges
                isChecked={is_active}
                checkboxLabel={`${category}`}
                description={note}
                deductionValue={percentage_deduction}
                flatFeeValue={fee_cap}
                walletValue={payment_mode}
                onChangeDeduction={(e) => onChangeDeduction(index, e)}
                onChangeFlatFee={(e) => onChangeFlatFee(index, e)}
                onChangeWallet={(e) => onChangeWallet(index, e)}
                onchangeCheckbox={() => onchangeCheckbox(index)}
                onChangeCategory={(e) => onChangeCategory(index, e)}
              />
            )
          )}
        <div className="mt-2" style={{ float: 'right' }}>
          <Button onClick={submitUpdate} type="submit">
            Save
          </Button>
        </div>
      </div>
    </StyleWrapper>
  );
};

export default index;

import React, { useState } from 'react';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import request, { headers } from '@request';
import { toast } from 'react-toastify';
import Button from '@components/Button/Button';
import SubmitSuccess from '@components/SubmitSuccess';
import FormInputComponent from '@components/formInputs/FormInputComponent';
import SelectWrapper from '@components/formInputs/selectWrapper';
const ImportPharmacyDataset = ({
  isModalOpen,
  setIsModalOpen,
  importDatasetEndpoint,
  refetch,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(0);

  const uploadCSV = async () => {
    if (!startRow || !endRow) {
      toast.error('Start row and end row is required');
      return;
    }
    if (!file) {
      toast.error('Please select a CSV file');
      return;
    }

    const fileExt = file.name.split('.').pop().toLowerCase();
    if (fileExt !== 'csv') {
      toast.error('Please ensure that the file is in CSV format');
      return;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      const fileContent = event.target.result;
      const rows = fileContent.split('\n'); // Split the content into rows using newline character
      // Remove empty rows (if any)
      const nonEmptyRows = rows.filter((row) => row.trim() !== '');

      // Check if there are at least three non-empty rows
      if (nonEmptyRows.length < 3) {
        toast.error('The CSV file must contain at least three rows');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('start_row', startRow);
      formData.append('end_row', endRow);
      // formData.append('location', location.value);

      setLoading(true);

      try {
        const res = await request.post(
          importDatasetEndpoint,
          formData,
          headers(localStorage.getItem('token'))
        );

        if (res.status === 200 || res.status === 201) {
          // Assuming successful upload status code
          refetch();
          setSubmitted(true);
          toast.success('dataset uploaded successfully');
        } else {
          throw new Error('Failed to upload inventory');
        }
      } catch (err) {
        console.error('Error uploading CSV:', err);
        toast.error('Failed to upload CSV. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    reader.readAsText(file); // Read the file as text
  };

  return (
    <ModalWrapper>
      <FormTitleSection onClick={() => setIsModalOpen(!isModalOpen)} />
      {submitted ? (
        <SubmitSuccess message="Dataset uploaded successfully" />
      ) : (
        <>
          <FormInputComponent
            label="Start Row"
            placeholder="Start Row"
            type="number"
            name="startrow"
            value={startRow}
            onChange={(e) => setStartRow(e.target.value)}
            labelColor="#2254D3"
            required
          />
          <FormInputComponent
            label="End Row"
            placeholder="End Row"
            type="number"
            name="endrow"
            value={endRow}
            onChange={(e) => setEndRow(e.target.value)}
            labelColor="#2254D3"
            required
          />
          <div className="form-group">
            <SelectWrapper label="Upload CSV<">
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
            </SelectWrapper>
          </div>
          <div className="modal-footer">
            <Button
              onClick={uploadCSV}
              type="submit"
              className="btn btn-primary"
            >
              {loading ? 'Importing list...' : 'Import'}
            </Button>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default ImportPharmacyDataset;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';
import '@styles/table.style.css';
import ClipLoader from 'react-spinners/ClipLoader';
import CustomSearch from '@components/formInputs/CustomSearch';
import Button from '@components/Button/Button';
import CreateForum from './CreateForum';
import { useFetchForum } from '@hooks/queries';

const InventoryAccordion = () => {
  const ref = useRef(null);
  const { data, refetch } = useFetchForum();
  const forum = data?.data?.data;
  console.log('forum', forum);
  const [currentData, setCurrentData] = useState(null);

  const [createForum, setCreateForum] = useState(false);
  const [updateForum, setUpdateForum] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState('');
  <div className="d-flex justify-content-between gap-3">
    <CustomSearch
      placeholder="Search..."
      handleChange={(e) => setSearchKeyWord(e.target.value)}
      value={searchKeyWord}
    />
    <Button type="button" onClick={() => setCreateForum(!createForum)}>
      Create new investigation
    </Button>
  </div>;
  const [idOfTable, setIdOfTable] = useState(-1);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const showModal = (item) => {
    setCurrentData(item);
    setIdOfTable(item.id);
  };

  return (
    <AccordionWrapper>
      <div className="d-flex justify-content-between gap-3">
        <CustomSearch
          placeholder="Search..."
          handleChange={(e) => setSearchKeyWord(e.target.value)}
          value={searchKeyWord}
        />
        <Button type="button" onClick={() => setCreateForum(true)}>
          Create Forum
        </Button>
      </div>
      {!loading && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" className="chek" />
                </th>
                <th>#</th>
                <th>image</th>
                <th>name</th>
                <th>coverage</th>
                <th>date created</th>
                <th>Number of active members</th>
                <th>description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {forum.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" className="chek" />
                  </td>
                  <td></td>
                  <td>
                    <img
                      style={{ width: '100px' }}
                      src={item?.image}
                      alt={item?.name}
                    />
                  </td>

                  <td>{item?.name}</td>
                  <td>{item?.coverage}</td>
                  <td>{item?.created_at}</td>
                  <td>{item?.members?.length}</td>

                  <td className="foot-note-cell">
                    {item?.description && item?.description.length > 50 ? (
                      <>
                        <span className="short-text">
                          {item?.description?.slice(0, 50)}...
                        </span>
                        <span className="full-text">{item?.description}</span>
                      </>
                    ) : (
                      item?.description
                    )}
                  </td>

                  <td
                    id={index}
                    className="three-dots"
                    onClick={() => showModal(item)}
                  >
                    <BsThreeDots />
                    {item?.id === idOfTable ? (
                      <div ref={ref} className="popup">
                        <p onClick>View members</p>
                        <p onClick={() => setUpdateForum(!updateForum)}>Edit</p>
                      </div>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {loading && (
        <div className="loader">
          <ClipLoader
            size={60}
            sizeUnit="px"
            color="#2254d3"
            loading={loading}
          />
        </div>
      )}
      {updateForum && (
        <CreateForum
          setCreateForum={setUpdateForum}
          createForum={updateForum}
          refetch={refetch}
          currentData={currentData}
        />
      )}

      {createForum && (
        <CreateForum
          setCreateForum={setCreateForum}
          createForum={createForum}
          refetch={refetch}
        />
      )}
    </AccordionWrapper>
  );
};

export default InventoryAccordion;

const AccordionWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
  }

  .popup {
    position: absolute;
    width: 175px;
    max-height: 200px;
    right: 50px;
    top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 10px;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 3;
    border-radius: 10px;
    overflow-y: auto;
    color: #071232;
    p:hover {
      display: inline-block;
    }
  }
  .chek {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #dfe8fc;
    box-sizing: border-box;
    border-radius: 5px;
    /* margin-top: 7px; */
  }
`;
